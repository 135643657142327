import taxonomyAPI from '../../api/taxonomy'
import * as types from '../types/taxonomy'
import * as commonTypes from '../types/common'

export const getDefaultState = () => ({
  taxonomicRegion: null,
  availableRegions: [
    {id: 'australasia', name: 'Australia'},
    {id: 'pikle-online-test', name: 'Pikle Online Staging'},
    {id: 'pikle-apps-test', name: 'Pikle Apps Staging'}
  ],
  currentTaxon: null,
  taxonCriteria: null
})

// initial state
const state = getDefaultState()

// getters
const getters = {
}

// actions
const actions = {
  [commonTypes.ACTION_RESET_STATE]: ({ commit }) => {
    commit(commonTypes.MUTATION_RESET_STATE)
  },
  [types.ACTION_LOAD_AVAILABLE_REGIONS]: async ({ state, commit }) => {
    // Load available regions
    const availableRegions = await taxonomyAPI.getTaxonRegions()
    commit(types.MUTATION_SET_AVAILABLE_REGIONS, availableRegions)
    // Set region to first in the list for now
    commit(types.MUTATION_SET_TAXONOMIC_REGION, state.availableRegions[0])
  },
  [types.ACTION_LOAD_TAXON_CRITERIA]: async ({ state, commit }) => {
    if (state.currentTaxon && state.taxonomicRegion) {
      // Set region to first in the list for now
      const taxonCriteriaResponse = await taxonomyAPI.getTaxonCriteria(state.currentTaxon.id, state.taxonomicRegion.id)
      commit(types.MUTATION_SET_TAXON_CRITERIA, taxonCriteriaResponse)
    }
  }
}

// mutations
const mutations = {
  // Reset to the default state
  [commonTypes.MUTATION_RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.MUTATION_SET_AVAILABLE_REGIONS] (state, newRegions) {
    state.availableRegions = newRegions
  },
  [types.MUTATION_SET_TAXONOMIC_REGION] (state, newRegion) {
    state.taxonomicRegion = newRegion
  },
  [types.MUTATION_SET_TAXON] (state, newTaxon) {
    state.currentTaxon = newTaxon
  },
  [types.MUTATION_SET_TAXON_CRITERIA] (state, taxonCriteria) {
    state.taxonCriteria = taxonCriteria
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
