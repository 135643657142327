// import axios from 'axios'
// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app'
// Load the auth feature into the firebase namespace.
import 'firebase/auth'
// Import vue stuff
import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Training from '@/views/Training.vue'
import Login from '@/views/Login'
import NotFound from '@/views/404.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/training',
      name: 'Training',
      component: Training,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '*',
      component: NotFound
    }
  ]
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // Require verified users
  //if (requiresAuth && (!currentUser || !currentUser.emailVerified)) {
  if (requiresAuth && (!currentUser)) {
    const forwardPath = window.location.pathname
    next({ name: 'Login', query: { from: forwardPath, failedVerification: (currentUser ? !currentUser.emailVerified : false) } })
  } else {
    next()
  }
})

export default router
