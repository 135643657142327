<template>
  <div class="mb-2">
    <v-card flat v-if="value">
      <v-card-text class="pl-0 py-0 d-flex align-center">
        <div v-for="(bcItem, index) in breadcrumbItems" :key="bcItem.id" class="d-none d-md-flex align-center flex-wrap">
          <v-chip
            outlined
            color="info"
            link
            light
            label
            class="breadcrumb-item"
            @click="ancestorCategorySelected(bcItem, index)"
          >
            {{bcItem.name}}
          </v-chip>
          <v-icon>mdi-slash-forward</v-icon>
        </div>
        <v-chip
          v-if="value"
          color="secondary"
          dark
          label
          :close="true"
          @click:close="clearCategory()"
        >
          {{value.name}}
        </v-chip>
      </v-card-text>
    </v-card>
    <div class="my-2 my-md-0">
      <div v-if="categoryOptions && categoryOptions.length > 0" class="mt-2">{{ value ? 'Sub Taxons' : emptyLabel }}</div>
      <div v-if="loadingCategories" class="d-flex align-center">
        <v-skeleton-loader
          class="mr-2 my-1"
          type="chip"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mr-2 my-1"
          type="chip"
        ></v-skeleton-loader>
      </div>
      <div v-else>
          <v-chip
            v-for="item in categoryOptions"
            :key="item.id"
            :outlined="true"
            class="mr-2 my-1"
            color="info"
            dark
            @click="childCategorySelected(item)"
          >
            {{ item.name }}
          </v-chip>
      </div>
    </div>
  </div>
</template>
<script>
import Constants from '../constants.js'
// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app'
// Load the auth feature into the firebase namespace.
import 'firebase/auth'
import axios from 'axios'

import { mapState } from 'vuex'

export default {
  name: 'TaxonomyItemSelect',
  props: {
    value: Object,
    breadcrumbItems: Array,
    emptyLabel: {
      type: String,
      default: 'Choose a taxon'
    }
  },
  data () {
    return {
      categoryOptions: [],
      loadingCategories: false,
      // currentRegion: 'australasia',
      // breadcrumbItems: []
    }
  },
  computed: {
    ...mapState({
      currentRegion: state => state.taxonomy.taxonomicRegion
    })
  },
  // mounted: function () {
  //   this.getOptions()
  // },
  watch: {
    // whenever value changes, we get the new children
    value: function () {
      this.getOptions()
    },
    currentRegion: function (newVal) {
      if (newVal) {
        this.getOptions()
      }
    }
  },
  methods: {
    getOptions: async function () {
      if (!this.currentRegion) {
        return
      }

      this.loadingCategories = true
      const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
      let categoriesURL = `${Constants.API_BASE_PATH}/taxons`
      if (this.value) {
        categoriesURL += `/${this.value.id}/children`
      }
      const res = await axios.get(categoriesURL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'x-pikle-taxon-region': this.currentRegion.id
        }
      });
      // console.log(`GOT categories response ${JSON.stringify(res.data)}`)
      this.categoryOptions = res.data
      // sort
      this.categoryOptions.sort((a, b) => {
        return a.order - b.order
      })
      this.loadingCategories = false
    },
    childCategorySelected: function (selection) {
      // Push the previous selected category into the breadcrumb trail
      if (this.value) {
        // this.breadcrumbItems.push(this.value)
        this.$emit('breadcrumbs', [...this.breadcrumbItems, this.value])
        // this.$emit('breadcrumbs', this.breadcrumbItems)
      }
      this.$emit('input', selection)
      this.$emit('onChange')
    },
    ancestorCategorySelected: function (selection, indexInBreadCrumb) {
      // this.$emit('breadcrumbs', this.breadcrumbItems.slice(0, indexInBreadCrumb))
      const newBreadCrumbs = [...this.breadcrumbItems].slice(0, indexInBreadCrumb)
      this.$emit('breadcrumbs', newBreadCrumbs)
      this.$emit('input', selection)
      this.$emit('onChange')
    },
    clearCategory: function () {
      this.$emit('breadcrumbs', [])
      this.$emit('input', null)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.breadcrumb-item {
  border-style: none;
  padding: 0;

  .v-chip__content {
    color: #03a9f4 !important;
  }
}
</style>
