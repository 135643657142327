<template>
  <div>
    <div v-if="loading" class="d-flex align-center">
      <v-skeleton-loader
        class="mr-2 mt-2"
        type="chip"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mr-2 mt-2"
        type="chip"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <v-chip v-for="criteria in criteriaSuggestions" :key="criteria.id" @click="selectCriteria(criteria)" class="mr-2 mt-2" close color="primary" outlined :close-icon="!readOnly ? 'mdi-plus-circle' : ''" @click:close="addCriteria(criteria)">
        {{ criteria.name }}
      </v-chip>
      <div v-if="!criteriaSuggestions || criteriaSuggestions.length === 0" class="text-caption">
        No more suggestions in this category
      </div>
    </div>
  </div>
</template>
<script>
// import Constants from '../constants.js'
// // This import loads the firebase namespace along with all its type information.
// import firebase from 'firebase/app'
// // Load the auth feature into the firebase namespace.
// import 'firebase/auth'
// import axios from 'axios'

import { mapState } from 'vuex'
// Veux types
import * as TaxonomyTypes from '@/store/types/taxonomy'

export default {
  name: 'CriteriaSelect',
  props: {
    // category: Object,
    selectedCriteria: Array,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // categoryCriteria: null,
      loading: false,
      // currentRegion: 'australasia'
    }
  },
  computed: {
    ...mapState({
      currentRegion: state => state.taxonomy.taxonomicRegion,
      category:  state => state.taxonomy.currentTaxon,
      categoryCriteria:  state => state.taxonomy.taxonCriteria
    }),
    criteriaSuggestions () {
      let res = []
      if (this.categoryCriteria) {
        res = this.categoryCriteria.filter(x => !this.selectedCriteria.map(function(c) { return c.id }).includes(x.id))
      }
      return res
    }
  },
  // mounted: function () {
  //   this.getCategoryCriteria()
  // },
  watch: {
    // whenever category changes, this function will run
    category: function () {
      this.getCategoryCriteria()
    },
    currentRegion: function (newVal) {
      if(newVal) {
        this.getCategoryCriteria()
      }
    }
  },
  methods: {
    getCategoryCriteria: async function () {
      if (!this.currentRegion || !this.category) {
        return
      }

      this.$store.dispatch(`taxonomy/${TaxonomyTypes.ACTION_LOAD_TAXON_CRITERIA}`)

      // this.loading = true
      // const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
      // const categoryId = this.category ? this.category.id : 'all'
      // let criteriaSuggestionsURL = `${Constants.API_BASE_PATH}/taxons/${categoryId}/criteria`
      // const res = await axios.get(criteriaSuggestionsURL, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${token}`,
      //     'x-pikle-taxon-region': this.currentRegion.id
      //   }
      // });
      // this.categoryCriteria = res.data

      // this.loading = false
      // this.$emit('onLoad', this.categoryCriteria)
    },
    addCriteria: function (criteria) {
      // Push the previous selected category into the breadcrumb trail
      if (!this.readOnly)
        this.$emit('onAdd', criteria)
    },
    selectCriteria: function (criteria) {
      this.$emit('onSelect', criteria)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.breadcrumb-item {
  border-style: none !important;

  .v-chip__content {
    color: #03a9f4 !important;
  }
}
</style>
