<template>
  <v-card class="mt-4">
    <v-card-title>
      Top Line Approval Stats
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div>
          <div>
            <strong>{{totalScrapedCriteria}}</strong> Criteria scraped
          </div>
          <div>
            <strong>{{totalFoundValues}}</strong> values found and approved
          </div>
          <div>
            <strong>{{totalManualValues}}</strong> values manually entered
          </div>
        </div>
        <div class="d-flex justify-center flex-grow-1 flex-shrink-0">
          <div class="approval-ratio d-flex align-center">
            <div>
              <div class="percentage">{{approvalPercentage}}%</div>
              <div class="text-caption">Value found ratio</div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ApprovalStats',
  props: {
    totalScrapedCriteria: Number,
    totalFoundValues: Number,
    totalManualValues: Number
  },
  computed : {
    approvalPercentage () {
      let percentage = 0
      if (this.totalScrapedCriteria && this.totalScrapedCriteria > 0) {
        percentage = (this.totalFoundValues / this.totalScrapedCriteria) * 100
      }
      return Number.parseFloat(percentage).toFixed(2)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.approval-ratio {
  font-size: 2em;
}
</style>
