<template>
  <div>
    <v-container fluid class="px-0">
      <v-row align="start" justify="center">
        <v-col cols="12">
          <v-row>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Criteria ID"
                    v-model="criteriaFilter"
                    placeholder="Enter criteria ID to fetch traning dtaa"
                  ></v-text-field>

                  <v-btn color="primary" @click="loadTrainingData">Refresh Training Data</v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="12">
                  Traning data
                  <br/>
                  {{renderTrainingData}}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    background-color="grey lighten-2"
                    color="cyan"
                    label="Spacey Training Positive"
                    :value="renderSpacyTrainingDataPos"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    background-color="grey lighten-2"
                    color="cyan"
                    label="Spacey Training Negative"
                    :value="renderSpacyTrainingDataNeg"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    background-color="grey lighten-2"
                    color="cyan"
                    label="Spacey Training Maybe Negative"
                    :value="renderSpacyTrainingDataMaybeNeg"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Constants from '../constants.js'
// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app'
// Load the auth feature into the firebase namespace.
import 'firebase/auth'
import axios from 'axios'


export default {
  name: 'TrainingView',
  data () {
    return {
      trainingData: null,
      criteriaFilter: null,
      spacyTrainDataPositive: null,
      spacyTrainDataNegative: null,
      spacyTrainDataPossiblyNegative: null
    }
  },
  computed : {
    renderTrainingData () {
      if (this.trainingData) {
        return JSON.stringify(this.trainingData)
      } else {
        return ""
      }
    },
    renderSpacyTrainingDataPos() {
      if (this.spacyTrainDataPositive) {
        let res = ""
        this.spacyTrainDataPositive.forEach((item) => {
          res += `\n${JSON.stringify(item)}`
        })
        return res
        // return JSON.stringify(this.spacyTrainData)
        // return this.spacyTrainData
      } else {
        return ""
      }
    },
    renderSpacyTrainingDataNeg() {
      if (this.spacyTrainDataNegative) {
        let res = ""
        this.spacyTrainDataNegative.forEach((item) => {
          res += `\n${JSON.stringify(item)}`
        })
        return res
        // return JSON.stringify(this.spacyTrainData)
        // return this.spacyTrainData
      } else {
        return ""
      }
    },
    renderSpacyTrainingDataMaybeNeg() {
      if (this.spacyTrainDataPossiblyNegative) {
        let res = ""
        this.spacyTrainDataPossiblyNegative.forEach((item) => {
          res += `\n${JSON.stringify(item)}`
        })
        return res
        // return JSON.stringify(this.spacyTrainData)
        // return this.spacyTrainData
      } else {
        return ""
      }
    }
  },
  methods: {
    loadTrainingData: async function () {
      if (firebase.auth().currentUser && this.criteriaFilter) {
        const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
        const getURL = `${Constants.API_BASE_PATH}/training`

        const queryParams = new URLSearchParams()
        queryParams.append('criteriaId', this.criteriaFilter)

        //console.log(`Calling option post ${JSON.stringify(this.newOptionModel)}`)
        const res = await axios.get(getURL, {
          params: queryParams,
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })

        if (res && res.status === 200 && res.data) {
          this.trainingData = res.data.trainingData

          this.spacyTrainDataPositive = []
          this.spacyTrainDataNegative = []
          this.spacyTrainDataPossiblyNegative = []
          // let contentStr = ""
          let label = `B-computers-${this.criteriaFilter}`
          this.trainingData.success.forEach((successItem) => {
            // const successContent = successItem.context ? successItem.context.replace(/[\r\n\t\x0B\x0C\u0085\u2028\u2029]+/g, " ") : successItem.value
            const successContent = successItem.context ? successItem.context.replace(/[\r\n\t\u0085\u2028\u2029]+/g, " ").trim() : successItem.value
            const nextTrainData = {content: successContent, annotation: []}
            const start = successContent.toLowerCase().indexOf(successItem.value.toLowerCase())
            const end = start + (successItem.value.length - 1)
            let mathingText = successItem.value
            if (start > 0) {
              // So case rematches the learn context
              mathingText = successContent.substring(start, end+1)
            }
            // contentStr = `${contentStr}${successItem} `
            nextTrainData.annotation.push({
              'label': [label],
              'points': [
                {
                  text: mathingText,
                  start: start,
                  end: end
                }
              ]
            })
            this.spacyTrainDataPositive.push(nextTrainData)
          })

          this.trainingData.possibleFailure.forEach((failureItem) => {
            const nextTrainData = {content: failureItem, annotation: []}
            // contentStr = `${contentStr}${successItem} `
            // nextTrainData.annotation.push({
            //   'label': [label],
            //   'points': [
            //     {
            //       text: failureItem,
            //       start: 0,
            //       end: failureItem.length - 1
            //     }
            //   ]
            // })
            this.spacyTrainDataPossiblyNegative.push(nextTrainData)
          })


          this.trainingData.failure.forEach((failureItem) => {
            const nextTrainData = {content: failureItem, annotation: []}
            // contentStr = `${contentStr}${successItem} `
            // nextTrainData.annotation.push({
            //   'label': [label],
            //   'points': [
            //     {
            //       text: failureItem,
            //       start: 0,
            //       end: failureItem.length - 1
            //     }
            //   ]
            // })
            this.spacyTrainDataNegative.push(nextTrainData)
          })
          // this.spacyTrainData.content = contentStr

          // this.spacyTrainData = [{'id': 1, 'paragraphs': []}]
          // this.trainingData.success.forEach((successItem) => {
          //   this.spacyTrainData[0].paragraphs.push({
          //     'raw': successItem,
          //     'sentences': [
          //       {
          //         'tokens': [{
          //           'id': 0,
          //           'head': successItem.length-1,
          //           'orth': successItem,
          //           'ner': `B-computers-${this.criteriaFilter}`
          //         }]
          //       }
          //     ]
          //   })
          // })


          // this.spacyTrainData = '['
          // this.trainingData.success.forEach((successItem) => {
          //   this.spacyTrainData += ` ("${successItem}", {"entities": [(${0}, ${successItem.length-1}, "B-computers-${this.criteriaFilter}")]}),`
          // })
          // this.trainingData.failure.forEach((failureItem) => {
          //   this.spacyTrainData += ` ("${failureItem}", {"entities": []}),`
          // })
          // this.spacyTrainData += ']'

        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
