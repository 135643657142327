import Constants from '../constants.js'
// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app'
// Load the auth feature into the firebase namespace.
import 'firebase/auth'
import axios from 'axios'

export default {
  async getTaxonRegions () {
    const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
    const regionsURL = `${Constants.API_BASE_PATH}/regions`
    const res = await axios.get(regionsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    const result = res && res.data ? res.data : null
    return result
  },
  async getTaxonCriteria (taxonId, taxonRegion) {
    const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
    let criteriaSuggestionsURL = `${Constants.API_BASE_PATH}/taxons/${taxonId}/criteria`
    const res = await axios.get(criteriaSuggestionsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-pikle-taxon-region': taxonRegion
      }
    });
    const result = res && res.data ? res.data : null
    return result
  },
  async getTaxonCriteriaSynonyms (taxonId, criteriaId, taxonRegion) {
    const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
    
    let getSynonymsURL = `${Constants.API_BASE_PATH}/taxons/${taxonId}/criteria/${criteriaId}/synonyms`
    const res = await axios.get(getSynonymsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-pikle-taxon-region': taxonRegion
      }
    });
    const result = res && res.data ? res.data : null
    return result
  }
}
