// src/plugins/vuetify/theme.js

// import colors from 'vuetify/lib/util/colors'

export default {
  primary: '#7e2fd8',
  secondary: '#2196f3',
  accent: '#03a9f4',
  error: '#e83203',
  warning: '#ff9800',
  info: '#607d8b',
  success: '#5bc35a'
}
