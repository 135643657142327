<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        fluid
      >
        <v-row align="center" justify="center">
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <div class="d-flex flex-column align-center justify-center mt-6">
              <div>
                <v-img
                  alt="Pikle Logo"
                  :src="require('../assets/logo.svg')"
                  max-width="155"
                ></v-img>
              </div>
              <div class="mb-6 mt-2 d-flex flex-column align-center">
                <div class="app-title">
                  Taxonomy Console
                </div>
                <div class="text-caption app-version">
                  ALPHA v0.0.1
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-form @submit.prevent="validateLoginForm">
            <v-card class="elevation-12" min-width="450">
              <v-toolbar
                light
                flat
              >
                <v-toolbar-title>Sign in</v-toolbar-title>
              </v-toolbar>
              <v-card-text>

                <v-text-field
                  v-model="email"
                  label="email"
                  name="login"
                  prepend-icon="mdi-account"
                  type="email"
                ></v-text-field>

                <v-text-field
                  id="password"
                  v-model="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                ></v-text-field>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn type="submit" color="primary">{{working ? 'Working...' : 'Login'}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app'

// Load the auth feature into the firebase namespace.
import 'firebase/auth'

export default {
  name: 'LoginView',
  components: {
  },
  data () {
    return {
      email: '',
      password: '',
      working: false,
      errors: []
    }
  },
  methods: {
    validateLoginForm: function () {
      // console.log(`Validate called`)
      if (this.email) {
        this.login()
      }

      this.errors = []

      if (!this.email) {
        this.errors.push({ 'id': 'email', 'message': 'email is required.' })
      }
    },
    login: function () {
      // console.log(`Logging in`)
      this.working = true
      let self = this
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
        () => {
          self.working = false
          // console.log(`Got a fb user ${user}`)
          // Redirect to the origional from url unless it was the login route itself
          // This can happen if the login succeeds but then the use is redirected back to login due to lack of email veriication.
          // console.log(`From ${this.$route.query.from}`)
          if (this.$route.query.from && this.$route.query.from !== '/login') {
            this.$router.replace(this.$route.query.from)
          } else {
            this.$router.replace('/')
          }
        },
        (err) => {
          self.working = false
          alert('Oops. ' + err.message)
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.comparison-list {
  display: flex;
  justify-content: space-around;
}
.app-title {
  color: #222;
  text-transform: uppercase;
}
.app-version {
  color: #7e2fd8;
}
</style>
