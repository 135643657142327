<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style lang="sass">
  $color-pack: false
  @import '~vuetify/src/styles/main.sass'
</style>
<style lang="scss">
  @import './styles/app.scss';
</style>
