<template>
  <div>
    <v-container fluid>
      <v-row class="pa-3 d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <v-img
            alt="Pikle Logo"
            :src="require('../assets/logo.svg')"
            max-width="80"
          ></v-img>
          <div class="ml-2">
            Taxonomy Console <span class="text-caption">v0.0.1a</span>
          </div>
        </div>
        <div>
          <v-select
            v-model="computedTaxonRegion"
            :items="availableRegions"
            item-text="name"
            item-value="id"
            return-object
            dense
          ></v-select>
        </div>
        <div>
          <v-btn small @click="logout">Logout</v-btn>
        </div>
      </v-row>
    </v-container>
    <v-container fluid class="px-0 grey lighten-5">
      <v-row align="start" justify="center">
        <v-col cols="4">
          <v-container fluid>
            <ApprovalStats
              v-if="topLineApprovalStats"
              :totalScrapedCriteria="topLineApprovalStats.totalScrapedCriteria"
              :totalFoundValues="topLineApprovalStats.totalFoundValues"
              :totalManualValues="topLineApprovalStats.totalManualValues"
            ></ApprovalStats>
            <v-card class="mt-4">
              <v-card-title>
                Existing Taxon Selector
              </v-card-title>
              <v-card-text>
                <TaxonomyItemSelect
                  v-model="computedSystemTaxon"
                  emptyLabel="Select an existing taxon"
                  :breadcrumbItems="selectedTaxonBreadCrumb"
                  @input="$v.newTaxonData.parent.$touch()"
                  @breadcrumbs="updateTaxonBreadCrumbs"
                ></TaxonomyItemSelect>
              </v-card-text>
            </v-card>
            <v-card class="mt-4">
              <v-card-title>
                User Taxons
              </v-card-title>
              <v-card-text>
                <v-list-item v-for="(value, key) in taxonSuggestions" :key="key" dense @click="selectUserTaxon(key, value)">
                  <v-list-item-content>
                    <v-list-item-title>{{value}}</v-list-item-title>
                    <v-list-item-subtitle>{{key}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
            <v-card class="mt-4">
              <v-card-title>
                Criteria Suggestions
              </v-card-title>
              <v-container fluid>
                <div class="d-flex align-start flex-column">
                  <div v-if="currentTaxon">
                    Load suggestions for {{ currentTaxon.isSuggestion ? `user defined taxon ${currentTaxon.name}` : currentTaxon.name }}
                  </div>
                  <div v-else>
                    Load suggestions for all taxons
                  </div>
                  <v-btn
                    class="my-4"
                    @click="loadSuggestedCriteria"
                  >
                    Load
                  </v-btn>
                </div>
              </v-container>
              <v-card-text>
                <v-list-item v-for="(value, index) in suggestedNewCriteriaList" :key="index" dense @click="selectCriteriaSuggestion(value.name)">{{value.name}} <span class="text-caption ml-2">{{value.count}}</span></v-list-item>
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
        <v-col cols="5">
          <v-row align="start" justify="center">
            <v-container fluid v-if="currentTaxon">
              <div class="d-flex align-center justify-space-between">
                <div>
                  Current Taxon
                  <strong>{{currentTaxon.name}}</strong>
                  <span v-if="currentTaxon.isSuggestion" class="text-caption info--text text--lighten-2"> (user generated)</span>
                </div>
                <v-btn
                  @click="clearTaxonSelection()"
                  text
                  small
                  color="primary"
                >
                  Clear Taxon
                </v-btn>
              </div>
            </v-container>
          </v-row>
          <v-row align="start" justify="center">
            <v-container fluid v-if="selectedSuggestion && selectedSuggestion.type !== 'taxon'">
              <div class="d-flex align-center justify-space-between">
                <div>
                  Selected
                  {{ selectedSuggestion.type }}
                  <strong>{{selectedSuggestion.value}}</strong>
                </div>
                <v-btn
                  @click="clearSelection()"
                  text
                  small
                  color="primary"
                >
                  Clear Selection
                </v-btn>
              </div>
              <v-divider></v-divider>
              <v-container class="grey lighten-5 pa-2" v-if="selectedSuggestion.criteria">
                <h5>Related Criteria ID</h5>
                <div>
                  {{selectedSuggestion.criteria}}
                </div>
              </v-container>
              <v-container class="grey lighten-5 pa-2" v-if="selectedSuggestion.data">
                <h5>Taxons</h5>
                <div>
                  <v-chip v-for="(taxon, key) in selectedSuggestion.data.taxons" :key="key"
                    :outlined="selectedTaxon && selectedTaxon.id !== taxon"
                    class="ma-2"
                    @click="selectTaxonFromCriteriaSuggestion(taxon)"
                  >
                    {{taxon}}
                  </v-chip>
                </div>
              </v-container>
              <v-container class="grey lighten-5 pa-2" v-if="selectedSuggestion.data">
                <h5>Criteria IDs</h5>
                <div>
                  <v-chip v-for="(criteriaId, key) in selectedSuggestion.data.criteriaIds" :key="key"
                    class="ma-2"
                  >
                    {{criteriaId}}
                  </v-chip>
                </div>
              </v-container>
              <v-container class="grey lighten-5 pa-2" v-if="selectedSuggestion.data">
                <h5>URLs</h5>
                <v-list-item v-for="(url, key) in selectedSuggestion.data.urls" :key="key" class="mb-2" :href="url" target="_blank">{{url}}</v-list-item>
                <!-- <div v-for="(url, key) in selectedSuggestion.data.urls" :key="key" class="mb-2">
                  {{url}}
                </div> -->
              </v-container>
              <!-- <v-container v-if="selectedSuggestion.data && selectedSuggestion.data.criteriaIds">
                <v-card class="mt-4">
                  <v-card-title>
                    Synonym Suggestions for {{selectedSuggestion.value}}
                  </v-card-title>
                  <v-card-text v-if="hasSynonymSuggestions">
                    <div v-for="(value, key) in synonymSuggestions" :key="key" class="mb-2">
                      <h4>{{key}}</h4>
                      <v-list-item v-for="(synonym, index) in value.values" :key="index" dense @click="selectSynonymSuggestion(key, synonym)">{{synonym}}</v-list-item>
                    </div>
                  </v-card-text>
                  <v-card-text v-else-if="synonymSuggestionsEmpty">
                    No synonyms found
                  </v-card-text>
                  <v-card-text v-else>
                    <div>
                      Load synonyms
                    </div>
                    <v-btn
                      class="my-4"
                      @click="loadSuggestedSynonyms(selectedSuggestion.data.criteriaIds)"
                    >
                      Load
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-container> -->

              <v-container v-if="selectedSuggestion.type === 'criteria'">
                <v-card class="mt-4">
                  <v-card-title>
                    Current Synonyms for {{selectedSuggestion.value}}
                  </v-card-title>
                  <v-card-text v-if="selectedSuggestion.synonyms">
                    <!-- <div v-for="(value) in selectedSuggestion.synonyms" :key="value.id" class="mb-2"> -->
                      <!-- <h4>{{value.id}}</h4> -->
                      <v-list-item v-for="synonym in selectedSuggestion.synonyms" :key="synonym.id" dense>{{synonym.value}}</v-list-item>
                    <!-- </div> -->
                  </v-card-text>
                  <v-card-title>
                    Synonym Suggestions for {{selectedSuggestion.value}}
                  </v-card-title>
                  <v-card-text v-if="hasSynonymSuggestions">
                    <div v-for="(value, key) in synonymSuggestions" :key="key" class="mb-2">
                      <h4>{{key}}</h4>
                      <v-list-item v-for="(synonym, index) in value.values" :key="index" dense @click="selectSynonymSuggestion(key, synonym)">{{synonym}}</v-list-item>
                      <!-- <div v-for="(synonym, index) in value.values" :key="index" @click="selectSynonymSuggestion(key, synonym)">{{synonym}}</div> -->
                    </div>
                  </v-card-text>
                  <v-card-text v-else-if="synonymSuggestionsEmpty">
                    No synonyms found
                  </v-card-text>
                  <v-card-text>
                    <div>
                      Load synonym suggestions
                    </div>
                    <v-btn
                      v-if="selectedSuggestion.data && selectedSuggestion.data.criteriaIds"
                      class="my-4"
                      @click="loadSuggestedSynonyms([selectedSuggestion.data.criteriaIds])"
                    >
                      Load
                    </v-btn>
                    <v-btn
                      v-else
                      class="my-4"
                      @click="loadSuggestedSynonyms([selectedSuggestion.criteria])"
                    >
                      Load
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-container>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row align="start" justify="center" fluid v-if="selectedTaxon">
            <v-container fluid>
              <div>All criteria for taxon <strong class="primary--text">{{selectedTaxonName}}</strong></div>
              <v-divider></v-divider>
              <v-container class="grey lighten-5">
                <v-row>
                  <CriteriaSelect class="mx-2" :selectedCriteria="[]" readOnly @onSelect="selectExistingCriteria"></CriteriaSelect>
                </v-row>
              </v-container>
            </v-container>
          </v-row>
          <v-row align="start" justify="center" fluid>
            <v-container fluid>
              <div>Actions</div>
              <v-divider></v-divider>
              <v-container class="grey lighten-5">
                <v-expansion-panels accordion>
                  <v-expansion-panel v-if="selectedSuggestion && (selectedSuggestion.type === 'criteria' || selectedSuggestion.type === 'synonym')">
                    <v-expansion-panel-header>Create new synonym</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <form>
                        <v-text-field
                          v-model="newSynonymData.taxon"
                          :error-messages="taxonErrors"
                          label="Taxon"
                          :counter="60"
                          required
                          @input="$v.newSynonymData.taxon.$touch()"
                          @blur="$v.newSynonymData.taxon.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="newSynonymData.criteria"
                          :error-messages="criteriaErrors"
                          label="Criteria"
                          :counter="120"
                          required
                          @input="$v.newSynonymData.criteria.$touch()"
                          @blur="$v.newSynonymData.criteria.$touch()"
                        ></v-text-field>
                        <v-text-field
                          v-model="newSynonymData.synonym"
                          :error-messages="synonymErrors"
                          label="Synonym"
                          :counter="120"
                          required
                          @input="$v.newSynonymData.synonym.$touch()"
                          @blur="$v.newSynonymData.synonym.$touch()"
                        ></v-text-field>
                        <v-btn
                          class="mr-4"
                          @click="createNewSynonym"
                          :disabled="$v.newSynonymData.$invalid"
                        >
                          Create
                        </v-btn>
                      </form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel v-if="currentTaxon || (selectedSuggestion && selectedSuggestion.type === 'criteria')">
                    <v-expansion-panel-header>Create new criteria</v-expansion-panel-header>
                    <v-expansion-panel-content>
                     <form>
                      <v-text-field
                        v-model="newCriteriaData.taxon"
                        :error-messages="newCriteriaTaxonErrors"
                        label="Taxon"
                        :counter="60"
                        required
                        @input="$v.newCriteriaData.taxon.$touch()"
                        @blur="$v.newCriteriaData.taxon.$touch()"
                      ></v-text-field>
                      <v-text-field
                        v-model="newCriteriaData.criteria"
                        :error-messages="newCriteriaErrors"
                        label="Criteria Keyword"
                        :counter="120"
                        required
                        @input="$v.newCriteriaData.criteria.$touch()"
                        @blur="$v.newCriteriaData.criteria.$touch()"
                      ></v-text-field>
                      <v-text-field
                        v-model="newCriteriaData.id"
                        :error-messages="newCriteriaIdErrors"
                        label="New criteria ID"
                        :placeholder="defaultNewCriteriaId"
                        :counter="60"
                        @input="$v.newCriteriaData.id.$touch()"
                        @blur="$v.newCriteriaData.id.$touch()"
                      ></v-text-field>
                      <v-btn
                        class="mr-4"
                        @click="createNewCriteria"
                        :disabled="$v.newCriteriaData.$invalid"
                      >
                        Create
                      </v-btn>
                    </form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel v-if="!selectedSuggestion || selectedSuggestion.type === 'taxon'">
                    <v-expansion-panel-header>Create new taxon</v-expansion-panel-header>
                    <v-expansion-panel-content>
                     <v-card flat class="pa-0">
                      <v-card-text class="pa-0">
                        <form>
                          <v-text-field
                            v-model="newTaxonData.taxon"
                            :error-messages="newTaxonErrors"
                            label="New taxon name"
                            :counter="60"
                            required
                            @input="$v.newTaxonData.taxon.$touch()"
                            @blur="$v.newTaxonData.taxon.$touch()"
                          ></v-text-field>

                          <v-text-field
                            v-model="newTaxonData.id"
                            :error-messages="newTaxonIdErrors"
                            label="New taxon ID"
                            :placeholder="defaultNewTaxonId"
                            :counter="60"
                            @input="$v.newTaxonData.id.$touch()"
                            @blur="$v.newTaxonData.id.$touch()"
                          ></v-text-field>
                          <h4 class="mb-2">New taxon parent</h4>
                          <v-btn
                            v-if="computedSystemTaxon"
                            class="mb-2"
                            @click="setParentToSystemTaxon"
                            small
                            color="success"
                          >
                            Use current taxon
                          </v-btn>
                          <TaxonomyItemSelect
                            v-model="newTaxonData.parent"
                            emptyLabel="Choose a parent"
                            :breadcrumbItems="newTaxonParentBreadCrumb"
                            @input="$v.newTaxonData.parent.$touch()"
                            @breadcrumbs="updateParentTaxonBreadCrumbs"
                          ></TaxonomyItemSelect>

                          <v-text-field
                            v-model.number="newTaxonData.order"
                            label="Order in parent"
                            type="number"
                          ></v-text-field>
                        </form>
                      </v-card-text>
                      <v-card-actions>
                        newTaxonParentBreadCrumb {{ JSON.stringify(newTaxonParentBreadCrumb) }}
                        <v-btn
                          class="mr-4"
                          @click="createNewTaxon"
                          :disabled="$v.newTaxonData.$invalid"
                        >
                          Create
                        </v-btn>

                        <v-btn
                          class="mr-4"
                          @click="clearNewTaxonData"
                          :disabled="!$v.newTaxonData.$dirty"
                          color="error"
                        >
                          Reset
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>
              </v-container>
              <!-- <v-container class="grey lighten-5" v-if="selectedSuggestion && selectedSuggestion.type === 'synonym'">
                <v-card>
                  <v-card-title>
                    Create new synonym
                  </v-card-title>
                  <v-card-text>
                    <form>
                      <v-text-field
                        v-model="newSynonymData.taxon"
                        :error-messages="taxonErrors"
                        label="Taxon"
                        :counter="60"
                        required
                        @input="$v.newSynonymData.taxon.$touch()"
                        @blur="$v.newSynonymData.taxon.$touch()"
                      ></v-text-field>
                      <v-text-field
                        v-model="newSynonymData.criteria"
                        :error-messages="criteriaErrors"
                        label="Criteria"
                        :counter="120"
                        required
                        @input="$v.newSynonymData.criteria.$touch()"
                        @blur="$v.newSynonymData.criteria.$touch()"
                      ></v-text-field>
                      <v-text-field
                        v-model="newSynonymData.synonym"
                        :error-messages="synonymErrors"
                        label="Synonym"
                        :counter="120"
                        required
                        @input="$v.newSynonymData.synonym.$touch()"
                        @blur="$v.newSynonymData.synonym.$touch()"
                      ></v-text-field>
                      <v-btn
                        class="mr-4"
                        @click="createNewSynonym"
                        :disabled="$v.newSynonymData.$invalid"
                      >
                        Create
                      </v-btn>
                    </form>
                  </v-card-text>
                </v-card>
              </v-container> -->

              <!-- <v-container class="grey lighten-5" v-if="selectedSuggestion && selectedSuggestion.type === 'criteria'">
                <v-card>
                  <v-card-title>
                    Create new criteria
                  </v-card-title>
                  <v-card-text>
                    <form>
                      <v-text-field
                        v-model="newCriteriaData.taxon"
                        :error-messages="newCriteriaTaxonErrors"
                        label="Taxon"
                        :counter="60"
                        required
                        @input="$v.newCriteriaData.taxon.$touch()"
                        @blur="$v.newCriteriaData.taxon.$touch()"
                      ></v-text-field>
                      <v-text-field
                        v-model="newCriteriaData.criteria"
                        :error-messages="newCriteriaErrors"
                        label="Criteria Keyword"
                        :counter="120"
                        required
                        @input="$v.newCriteriaData.criteria.$touch()"
                        @blur="$v.newCriteriaData.criteria.$touch()"
                      ></v-text-field>
                      <v-text-field
                        v-model="newCriteriaData.id"
                        :error-messages="newCriteriaIdErrors"
                        label="New taxon ID"
                        :placeholder="defaultNewCriteriaId"
                        :counter="60"
                        @input="$v.newCriteriaData.id.$touch()"
                        @blur="$v.newCriteriaData.id.$touch()"
                      ></v-text-field>
                      <v-btn
                        class="mr-4"
                        @click="createNewCriteria"
                        :disabled="$v.newCriteriaData.$invalid"
                      >
                        Create
                      </v-btn>
                    </form>
                  </v-card-text>
                </v-card>
              </v-container> -->

              <!-- <v-container class="grey lighten-5" v-if="!selectedSuggestion || selectedSuggestion.type === 'taxon'">
                <v-card>
                  <v-card-title>
                    Create new Taxon
                  </v-card-title>
                  <v-card-text>
                    <form>
                      <v-text-field
                        v-model="newTaxonData.taxon"
                        :error-messages="newTaxonErrors"
                        label="New taxon name"
                        :counter="60"
                        required
                        @input="$v.newTaxonData.taxon.$touch()"
                        @blur="$v.newTaxonData.taxon.$touch()"
                      ></v-text-field>

                      <v-text-field
                        v-model="newTaxonData.id"
                        :error-messages="newTaxonIdErrors"
                        label="New taxon ID"
                        :placeholder="defaultNewTaxonId"
                        :counter="60"
                        @input="$v.newTaxonData.id.$touch()"
                        @blur="$v.newTaxonData.id.$touch()"
                      ></v-text-field>
                      <h4 class="mb-2">New taxon parent</h4>
                      <v-btn
                        v-if="selectedSystemTaxon"
                        class="mb-2"
                        @click="setParentToSystemTaxon"
                      >
                        Use current taxon
                      </v-btn>
                      <TaxonomyItemSelect
                        v-model="newTaxonData.parent"
                        emptyLabel="Choose a parent"
                        :breadcrumbItems="newTaxonParentBreadCrumb"
                        @input="$v.newTaxonData.parent.$touch()"
                        @breadcrumbs="updateParentTaxonBreadCrumbs"
                      ></TaxonomyItemSelect>

                      <v-text-field
                        v-model="newTaxonData.order"
                        label="Order in parent"
                        type="number"
                      ></v-text-field>
                    </form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      class="mr-4"
                      @click="createNewTaxon"
                      :disabled="$v.newTaxonData.$invalid"
                    >
                      Create
                    </v-btn>

                    <v-btn
                      class="mr-4"
                      @click="clearNewTaxonData"
                      :disabled="!$v.newTaxonData.$dirty"
                      color="error"
                    >
                      Reset
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-container> -->

            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Constants from '../constants.js'
// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app'
// Load the auth feature into the firebase namespace.
import 'firebase/auth'
import axios from 'axios'

import CriteriaSelect from '../components/CriteriaSelect.vue'
import TaxonomyItemSelect from '../components/TaxonomyItemSelect.vue'
import ApprovalStats from '../components/ApprovalStats.vue'

import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'

import { mapState } from 'vuex'
// Veux types
import * as TaxonomyTypes from '@/store/types/taxonomy'

import taxonomyAPI from '../api/taxonomy'

export default {
  name: 'DashboardView',
  data () {
    return {
      criteriaFilter: null,
      suggestedTaxonData: null,
      matchingLearningData: {criteriaSuggestions: null, synonymSuggestions: null},
      selectedSuggestion: null,
      selectedTaxon: null,
      // systemTaxon: null,
      newSynonymData: {taxon: '', criteria: '', synonym: ''},
      newCriteriaData: {taxon: '', criteria: ''},
      newTaxonData: {taxon: '', id: null, parent: null, order: 1},
      newTaxonParentBreadCrumb: [],
      selectedTaxonBreadCrumb: [],
      topLineApprovalStats: null,
      newCriteriaIdNotUnique: false
      // currentRegion: 'australasia'
    }
  },
  mixins: [validationMixin],
  validations: {
    newSynonymData: {
      taxon: { required, maxLength: maxLength(60) },
      criteria: { required, maxLength: maxLength(120) },
      synonym: { required, maxLength: maxLength(120) }
    },
    newCriteriaData: {
      taxon: { required, maxLength: maxLength(60) },
      id: { maxLength: maxLength(60) },
      criteria: { required, maxLength: maxLength(120) }
    },
    newTaxonData: {
      taxon: { required, maxLength: maxLength(60) },
      id: { maxLength: maxLength(60) },
      parent: { required }
    }
  },
  components: {
    CriteriaSelect,
    TaxonomyItemSelect,
    ApprovalStats
  },
  computed : {
    ...mapState({
      currentRegion: state => state.taxonomy.taxonomicRegion,
      availableRegions: state => state.taxonomy.availableRegions,
      systemTaxon: state => state.taxonomy.currentTaxon,
    }),
    computedTaxonRegion: {
      // getter
      get: function () {
        return this.currentRegion
      },
      // setter
      set: function (newRegion) {
        this.$store.commit(`taxonomy/${TaxonomyTypes.MUTATION_SET_TAXONOMIC_REGION}`, newRegion)
      }
    },
    computedSystemTaxon: {
      // getter
      get: function () {
        return this.systemTaxon
      },
      // setter
      set: function (newValue) {
        // this.systemTaxon = newValue
        this.$store.commit(`taxonomy/${TaxonomyTypes.MUTATION_SET_TAXON}`, newValue)
        // console.log(`System taxon = ${JSON.stringify(newValue)}`)
        this.currentTaxon = newValue
        if (newValue) {
          this.selectedSuggestion = null
        }
      }
    },
    selectedTaxonName () {
      return this.selectedTaxon ? this.selectedTaxon.name : '';
    },
    currentTaxon: {
      // getter
      get: function () {
        return this.selectedTaxon
      },
      // setter
      set: function (newValue) {
        this.selectedTaxon = newValue
        if (newValue) {
          this.newSynonymData.taxon = newValue.id
          this.newCriteriaData.taxon = newValue.id
        } else {
          this.newSynonymData.taxon = null
          this.newCriteriaData.taxon = null
        }
      }
    },
    // selectedTaxonObject () {
    //   return {id: this.selectedTaxon}
    // },
    taxonSuggestions () {
      if (this.suggestedTaxonData && this.suggestedTaxonData.candidateTaxons) {
        return this.suggestedTaxonData.candidateTaxons
      } else {
        return {}
      }
    },
    synonymSuggestions () {
      if (this.matchingLearningData && this.matchingLearningData.synonymSuggestions) {
        return this.matchingLearningData.synonymSuggestions
      } else {
        return {}
      }
    },
    hasSynonymSuggestions () {
      return Object.keys(this.synonymSuggestions).length > 0
    },
    synonymSuggestionsEmpty () {
      let isSynonymsResEmpty = false
      // console.log(`Checking isSynonymsResEmpty = ${JSON.stringify(this.matchingLearningData.synonymSuggestions)}`)
      if (this.matchingLearningData && this.matchingLearningData.synonymSuggestions && Object.keys(this.matchingLearningData.synonymSuggestions).length === 0) {
        isSynonymsResEmpty = true
      }
      // console.log(`Return isSynonymsResEmpty = ${isSynonymsResEmpty}`)
      return isSynonymsResEmpty
    },
    suggestedNewCriteriaList () {
      if (this.matchingLearningData && this.matchingLearningData.criteriaSuggestions) {
        const res = []
        Object.keys(this.matchingLearningData.criteriaSuggestions).forEach((item) => {
          const count = this.matchingLearningData.criteriaSuggestions[item].successCount
          res.push({name: item, count: count})
        })
        res.sort((a, b) => b.count - a.count)
        return res
      } else {
        return []
      }
    },
    renderMatchingData () {
      if (this.matchingLearningData) {
        return JSON.stringify(this.matchingLearningData)
      } else {
        return ""
      }
    },
    taxonErrors () {
      const errors = []
      if (!this.$v.newSynonymData.taxon.$dirty) return errors
      !this.$v.newSynonymData.taxon.maxLength && errors.push('Taxon must be at most 60 characters long')
      !this.$v.newSynonymData.taxon.required && errors.push('Taxon is required.')
      return errors
    },
    criteriaErrors () {
      const errors = []
      if (!this.$v.newSynonymData.criteria.$dirty) return errors
      !this.$v.newSynonymData.criteria.maxLength && errors.push('Criteria must be at most 120 characters long')
      !this.$v.newSynonymData.criteria.required && errors.push('Criteria is required.')

      return errors
    },
    synonymErrors () {
      const errors = []
      if (!this.$v.newSynonymData.synonym.$dirty) return errors
      !this.$v.newSynonymData.synonym.maxLength && errors.push('Synonym must be at most 120 characters long')
      !this.$v.newSynonymData.synonym.required && errors.push('A synonym is required.')
      return errors
    },
    newCriteriaTaxonErrors () {
      const errors = []
      if (!this.$v.newCriteriaData.taxon.$dirty) return errors
      !this.$v.newCriteriaData.taxon.maxLength && errors.push('Taxon must be at most 60 characters long')
      !this.$v.newCriteriaData.taxon.required && errors.push('Taxon is required.')
      return errors
    },
    newCriteriaErrors () {
      const errors = []
      if (!this.$v.newCriteriaData.criteria.$dirty) return errors
      !this.$v.newCriteriaData.criteria.maxLength && errors.push('Criteria must be at most 120 characters long')
      !this.$v.newCriteriaData.criteria.required && errors.push('Criteria is required.')
      return errors
    },
    newCriteriaIdErrors () {
      const errors = []
      if (!this.$v.newCriteriaData.id.$dirty) return errors
      !this.$v.newCriteriaData.id.maxLength && errors.push('Criteria ID must be at most 60 characters long')

      if (this.newCriteriaIdNotUnique) {
        errors.push('The criteria requires a unique id')
      }

      return errors
    },
    newTaxonErrors () {
      const errors = []
      if (!this.$v.newTaxonData.taxon.$dirty) return errors
      !this.$v.newTaxonData.taxon.maxLength && errors.push('Taxon must be at most 60 characters long')
      !this.$v.newTaxonData.taxon.required && errors.push('Taxon is required.')
      return errors
    },
    newTaxonIdErrors () {
      const errors = []
      if (!this.$v.newTaxonData.id.$dirty) return errors
      !this.$v.newTaxonData.id.maxLength && errors.push('Taxon ID must be at most 60 characters long')
      return errors
    },
    newTaxonParentErrors () {
      const errors = []
      if (!this.$v.newTaxonData.parent.$dirty) return errors
      !this.$v.newTaxonData.parent.required && errors.push('Taxon parent is required.')
      return errors
    },
    defaultNewTaxonId () {
      let res = ''
      if (this.newTaxonData && this.newTaxonData.taxon) {
        const dashedName = this.newTaxonData.taxon.replace(/ /g, "-")
        res = dashedName.toLowerCase()
      }
      return res
    },
    defaultNewCriteriaId () {
      let res = ''
      if (this.newCriteriaData && this.newCriteriaData.criteria) {
        const dashedName = this.newCriteriaData.criteria.replace(/ /g, "-")
        res = dashedName.toLowerCase()
      }
      return res
    }
  },
  mounted: function () {
    // this.loadSuggestedTaxons()
    // this.loadApprovalStats()
    this.$store.dispatch(`taxonomy/${TaxonomyTypes.ACTION_LOAD_AVAILABLE_REGIONS}`)
  },
  watch: {
    currentRegion: function (newVal) {
      if (newVal) {
        // console.log(`Region changed`)
        this.loadSuggestedTaxons()
        this.loadApprovalStats()
      }
    },
    systemTaxon: function (newVal) {
      if (newVal) {
        // Load existing criteria
        
      }
    }
  },
  methods: {
    selectUserTaxon: function (taxonKey, taxonName) {
      this.computedSystemTaxon = null
      this.currentTaxon = {id: taxonKey, name: taxonName, 'isSuggestion': true}
      this.selectedSuggestion = {'type': 'taxon', 'value': taxonName}

      this.matchingLearningData.synonymSuggestions = null
    },
    selectSynonymSuggestion: function (suggestionKey, synonym) {
      // this.selectedTaxon = null
      // this.newSynonymData.taxon = ''
      // this.currentTaxon = null
      this.newSynonymData.criteria = suggestionKey
      this.newSynonymData.synonym = synonym.toLowerCase()
      this.selectedSuggestion = {'type': 'synonym', 'criteria': suggestionKey, 'value': synonym, 'data': this.matchingLearningData.synonymSuggestions[suggestionKey]}
    },
    selectCriteriaSuggestion: function (suggestionKey) {
      // this.selectedTaxon = null
      // this.newCriteriaData.taxon = ''
      // this.currentTaxon = null
      this.newCriteriaData.criteria = suggestionKey
      this.selectedSuggestion = {'type': 'criteria', 'value': suggestionKey, 'data': this.matchingLearningData.criteriaSuggestions[suggestionKey]}

      this.matchingLearningData.synonymSuggestions = null
    },
    selectTaxonFromCriteriaSuggestion: function (taxonId) {
      // this.currentTaxon = {id: taxonId}
      this.newSynonymData.taxon = taxonId
      this.newCriteriaData.taxon = taxonId
    },
    setParentToSystemTaxon: function () {
      this.newTaxonData.parent = this.computedSystemTaxon
      this.updateParentTaxonBreadCrumbs([...this.selectedTaxonBreadCrumb])
    },
    // selectTaxon: function (taxon) {
    //   this.selectedTaxon = taxon
    //   this.newSynonymData.taxon = taxon
    //   this.newCriteriaData.taxon = taxon
    // },
    clearTaxonSelection: function () {
      this.currentTaxon = null
      this.computedSystemTaxon = null
      this.selectedTaxonBreadCrumb = []
      if (this.selectedSuggestion && this.selectedSuggestion.type === 'taxon') {
        this.selectedSuggestion = null
      }
    },
    clearSelection: function () {

      this.newSynonymData.criteria = ''
      this.newSynonymData.synonym = ''

      // this.newCriteriaData.taxon = ''
      this.newCriteriaData.criteria = ''

      this.matchingLearningData.synonymSuggestions = null
      this.matchingLearningData.criteriaSuggestions = null

      if (this.selectedSuggestion && this.selectedSuggestion.type !== 'taxon') {
        this.selectedSuggestion = null
      }
    },
    clearNewTaxonData: function () {
      this.$v.newTaxonData.$reset()
      this.newTaxonData.taxon = ''
      this.newTaxonData.id = null
      this.newTaxonData.parent = null
      this.newTaxonData.order = 1
      this.newTaxonParentBreadCrumb = []
    },
    loadApprovalStats: async function () {
      if (firebase.auth().currentUser && this.currentRegion) {
        const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
        let getURL = `${Constants.API_BASE_PATH}/discover/stats`

        //console.log(`Calling option post ${JSON.stringify(this.newOptionModel)}`)
        const res = await axios.get(getURL, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-pikle-taxon-region': this.currentRegion.id
          }
        })

        if (res && res.status === 200 && res.data && res.data.stats) {
          // console.log(`Got stats response ${JSON.stringify(res.data.stats)}`)
          this.topLineApprovalStats = res.data.stats
        }
      }
    },
    loadSuggestedTaxons: async function () {
      if (firebase.auth().currentUser && this.currentRegion) {
        const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
        const getURL = `${Constants.API_BASE_PATH}/discover/taxons`

        //console.log(`Calling option post ${JSON.stringify(this.newOptionModel)}`)
        const res = await axios.get(getURL, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-pikle-taxon-region': this.currentRegion.id
          }
        })

        if (res && res.status === 200 && res.data) {
          this.suggestedTaxonData = res.data.matches
        }
      }
    },
    loadSuggestedCriteria: async function () {
      if (firebase.auth().currentUser) {
        const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())

        // Then discover new criteria
        let getURL = `${Constants.API_BASE_PATH}/discover/criteria`

        if (this.currentTaxon) {
          getURL = `${getURL}?taxon=${this.currentTaxon.id}`
        }

        //console.log(`Calling option post ${JSON.stringify(this.newOptionModel)}`)
        const res = await axios.get(getURL, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-pikle-taxon-region': this.currentRegion.id
          }
        })

        if (res && res.status === 200 && res.data && res.data.matches) {
          // console.log(`Got suggestion response ${JSON.stringify(res.data.matches)}`)
          this.matchingLearningData.criteriaSuggestions = res.data.matches.criteriaSuggestions
        }
      }
    },
    loadSuggestedSynonyms: async function (criteriaIds) {
      if (firebase.auth().currentUser && criteriaIds) {
        const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
        let postURL = `${Constants.API_BASE_PATH}/discover/synonyms`

        const postBody = {
          criteriaIds: criteriaIds
        }

        if (this.currentTaxon) {
          postBody.taxon = this.currentTaxon.id
        }

        //console.log(`Calling option post ${JSON.stringify(this.newOptionModel)}`)
        const res = await axios.post(postURL, postBody, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-pikle-taxon-region': this.currentRegion.id
          }
        })

        if (res && res.status === 200 && res.data && res.data.matches) {
          // console.log(`Got suggestion response ${JSON.stringify(res.data.matches)}`)
          this.matchingLearningData.synonymSuggestions = res.data.matches
        }
      }
    },
    updateTaxonBreadCrumbs: function (newList) {
      this.selectedTaxonBreadCrumb = newList
    },
    updateParentTaxonBreadCrumbs: function (newList) {
      this.newTaxonParentBreadCrumb = newList
    },
    createNewCriteria: async function () {
      if (firebase.auth().currentUser && this.newCriteriaData.taxon && this.newCriteriaData.criteria) {

        const newCriteriaId = this.newCriteriaData.id || this.defaultNewCriteriaId

        const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())
        const validateURL = `${Constants.API_BASE_PATH}/keywords/${newCriteriaId}`
        const postURL = `${Constants.API_BASE_PATH}/taxons/${this.newCriteriaData.taxon}/criteria`

        // Validate unique id
        const validationResponse = await axios.get(validateURL, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-pikle-taxon-region': this.currentRegion.id
          }
        })
        // console.log(`Validation Response = ${JSON.stringify(validationResponse)}`)
        let keywordFound = false
        if (validationResponse && validationResponse.data && validationResponse.data.status === 'success' && validationResponse.data.keywordData) {
          keywordFound = true
        }
        this.newCriteriaIdNotUnique = keywordFound

        // console.log(`keywordFound = ${keywordFound}`)

        if (!keywordFound) {
          const newCriteriaBody = {
            id: newCriteriaId,
            value: this.newCriteriaData.criteria
          }

          // console.log(`Creating new criteria ${JSON.stringify(newCriteriaBody)} in taxon ${this.newCriteriaData.taxon}`)
          const res = await axios.post(postURL, newCriteriaBody, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-pikle-taxon-region': this.currentRegion.id
            }
          })

          if (res && res.status === 200 && res.data) {
            this.$v.newCriteriaData.$touch()
          }

          this.$v.newCriteriaData.$reset()
          this.newCriteriaData.taxon = ''
          this.newCriteriaData.criteria = ''
          this.newCriteriaData.id = null
          this.newCriteriaIdNotUnique = false
        }
      }
    },
    createNewSynonym: async function () {
      if (firebase.auth().currentUser && this.newSynonymData.taxon && this.newSynonymData.criteria && this.newSynonymData.synonym) {
        const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())

        const postURL = `${Constants.API_BASE_PATH}/taxons/${this.newSynonymData.taxon}/criteria/${this.newSynonymData.criteria}/synonyms`

        //console.log(`Calling option post ${JSON.stringify(this.newOptionModel)}`)
        const res = await axios.post(postURL, {"keyword": this.newSynonymData.synonym}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-pikle-taxon-region': this.currentRegion.id
          }
        })

        if (res && res.status === 200 && res.data) {
          this.$v.newSynonymData.$touch()
          // this.matchingLearningData = res.data.matches
        }

        this.$v.newSynonymData.$reset()
        this.newSynonymData.taxon = ''
        this.newSynonymData.criteria = ''
        this.newSynonymData.synonym = ''
      }
    },
    createNewTaxon: async function () {
      if (firebase.auth().currentUser && this.newTaxonData.taxon && this.newTaxonData.parent) {
        const token = firebase.auth().currentUser && (await firebase.auth().currentUser.getIdToken())

        const postURL = `${Constants.API_BASE_PATH}/taxons/${this.newTaxonData.parent.id}/children`
        const newTaxonId = this.newTaxonData.id || this.defaultNewTaxonId
        // const breadCrumbIds = this.newTaxonParentBreadCrumb.map(nextBC => nextBC.id)
        // const ancestorIds = [
        //   this.newTaxonData.parent.id,
        //   ...breadCrumbIds
        // ]
        const breadCrumbItems = this.newTaxonParentBreadCrumb.map(nextBC => {return {
          id: nextBC.id,
          name: nextBC.name
        }})

        const ancestorIds = [
          ...breadCrumbItems,
          {id: this.newTaxonData.parent.id, name: this.newTaxonData.parent.name},
        ]


        const newTaxonBody = {
          id: newTaxonId,
          name: this.newTaxonData.taxon,
          order: this.newTaxonData.order,
          // ancestors: ancestorIds.toString() // TODO this needs to be an array of {id: taxon-id, name: taxon-name}
          ancestors: ancestorIds // Fixed to contain the correct array of items
        }

        // console.log(`Going to save new taxon ${JSON.stringify(newTaxonBody)} under ${this.newTaxonData.parent.id}`)
        const res = await axios.post(postURL, newTaxonBody, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-pikle-taxon-region': this.currentRegion.id
          }
        })

        if (res && res.status === 200 && res.data) {
          this.$v.newTaxonData.$touch()
        }

        this.clearNewTaxonData()
      }
    },
    selectExistingCriteria(criteria) {
      // this.selectSynonymSuggestion(criteria.id, "")
      this.newSynonymData.criteria = criteria.id
      this.newSynonymData.synonym = ""
      this.selectedSuggestion = {'type': 'criteria', 'criteria': criteria.id, 'value': criteria.name, 'data': null, 'synonyms': null}
      this.matchingLearningData.synonymSuggestions = null
      this.matchingLearningData.criteriaSuggestions = null
      if (this.selectedTaxon) {
        taxonomyAPI.getTaxonCriteriaSynonyms(this.selectedTaxon.id, criteria.id, this.currentRegion.id).then(res => {
          if (res) {
            this.selectedSuggestion.synonyms = res
          }
        })
      }
    },
    logout: function () {
      firebase.auth().signOut().then(() => {
        this.$router.replace('/login')
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
